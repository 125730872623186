<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">审核管理</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/AuditManage/StudentDiscounts"
            >活动审核 /
          </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </div>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name">基本信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>
              姓名：<span>{{ data.name }}</span>
            </p>
            <p>
              性别：<span>{{ data.sex== 1 ? '男' : data.sex== 2 ? '女' : '未知'}}</span>
            </p>
            <p>
              手机号：<span>{{ data.mobile }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              证件号：<span>{{ data.idNo }}</span>
            </p>
            <p>
              审核状态：<span><a-tag :color="data.status == 1 ? 'orange' : data.status == 2 ? 'red' : data.status == 3 ? 'green' : ''">{{data.status == 1 ? '待审核' : data.status == 2 ? '已驳回' : data.status == 3 ? '已通过' : '-'}}</a-tag></span>
            </p>
            <p>
              提交时间：<span>{{ data.applyTime }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">审核信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>
              活动名称：<span>{{ data.operationName }}</span>
            </p>
            <p>
              就读院校：<span>{{ data.entityName }}</span>
            </p>
            <p>
              学历：<span>{{ data.degree }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              专业：<span>{{ data.major }}</span>
            </p>
            <p>
              年级：<span>{{ data.grade }}</span>
            </p>
            <p>
              预计毕业时间：<span v-if="data.expirationDate">{{ data.expirationDate.substring(0,10) }}</span>
            </p>
          </div>
          <div class="line_item">
            <p>
              学信网截图：
              <viewer class="pictrue" v-if="data.evidence">
                <img :src="data.evidence" alt="" />
              </viewer>
            </p>
          </div>
          <div class="line_item" v-if="data.status == 2">
            <p>
              驳回原因：<span>{{ data.reason }}</span>
            </p>
          </div>
        </div>
      </div>
      
      <div class="foot_btn">
        <a-button
          type="primary"
          class="btn"
          :loading="loading"
          v-if="data.status == 1"
          v-hasPermi="['qualification:review:edit']"
          @click="onPassAsk"
          >通过</a-button
        >
        <a-button
          class="all_boder_btn btn"
          v-if="data.status == 1"
          v-hasPermi="['qualification:review:edit']"
          :loading="loading"
          @click="edit = true"
          >驳回</a-button
        >
        <a-button class="all_boder_btn"
          ><router-link
            class="crumbs_item"
            tag="a"
            to="/admin/AuditManage/StudentDiscounts"
            >返回</router-link
          ></a-button
        >
      </div>

      <!-- 驳回原因 -->
      <a-modal v-model="edit" align="center" title="驳回">
        <a-textarea
          v-model="rejectReason"
          placeholder="请填写驳回原因，以帮助用户更好的通过审核"
          :auto-size="{ minRows: 3, maxRows: 10 }"
        />
        <template slot="footer">
          <a-button type="primary" @click="editOk">确认</a-button>
          <a-button @click="editCancel">取消</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      edit: false,
      rejectReason: "",
      type:'',
      data: {},
      loading:false,
      coupons:[]
    };
  },
  // 事件处理器
  methods: {
    editOk() {
      if (!this.rejectReason) {
        this.$message.warn("请填写驳回原因");
        return false;
      } else {
        this.edit = false;
        this.onAudit(2);
      }
    },
    editCancel(e) {
      this.edit = false;
    },
    // 通过审核确认
    onPassAsk(){
      let _that = this
      this.$confirm({
        title: '确定信息无误审核通过吗?',
        okText: '确认',
        onOk() {
          _that.onAudit(3)
        },
        onCancel() {},
      });
    },

    // 获取优惠券id
    getCouponsList(){
      this.$ajax({
        url: "/hxclass-management/dict/get",
        method: "get",
        params: {
          c: 'student_coupon_id',
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.coupons = res.data.dictValue.split(',')
        } else {
          this.$message.success(res.message);
        }
      });
    },

    // 操作审核
    onAudit(status){
      // status 审核状态 1 待审核 2驳回3 通过
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/qualification/review",
        method: "POST",
        params: {
          coupons: this.coupons,
          id: this.id,
          status: status,
          reason: this.rejectReason,

        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.$router.push("/admin/AuditManage/StudentDiscounts");
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    // 学历信息详情
    getDetail() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/qualification/manage/detail",
        params: {
          id: this.id
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.data = res.data;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getDetail();
    this.getCouponsList()
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
  },
};
</script>

<style lang="less" scoped>
.Up_Down_inner {
  margin-bottom: 10px;
  .all_left_name {
    font-weight: 500;
    color: #000000;
  }
  .check {
    font-size: 14px;
    margin-top: 15px;
    i {
      font-style: normal;
      color: #ff0000;
    }
  }
}
.bgDiv {
  .line_item {
    display: flex;
    margin-bottom: 24px;
    p {
      width: calc(100% / 3);
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      display: flex;
      span {
        font-weight: 500;
        color: #333333;
      }
    }
    .pictrue{
      display: inline-block;
      img{
        width: 300px;
      }
    }
  }
  .line_item:last-child {
    margin-bottom: 0;
  }
}
.foot_btn {
  margin-top: 20px;
  .btn {
    margin-right: 20px;
  }
}
</style>
